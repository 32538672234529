function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

document.addEventListener(
  'scroll',
  debounce(() => {
    if (document.body.parentNode !== null) {
      const angle =
        Math.floor(
          ((document.body.scrollTop ||
            (document.body.parentNode as HTMLElement).scrollTop) /
            ((document.body.parentNode as HTMLElement).scrollHeight -
              (document.body.parentNode as HTMLElement).clientHeight)) *
            100
        ) *
          3.6 +
        110;

      const isHome =
        (document.body.parentNode as HTMLElement).scrollTop <
        window.innerHeight / 2;

      document.body.style.setProperty('--a', angle + 'deg');
      document.body.style.setProperty('--y', (isHome ? 75 : 50) + '%');
    }
  }, 25)
);

document.addEventListener('dblclick', () => {
  document.body.classList.toggle('light-mode');
});
